import React, {useState, useEffect} from 'react';
import {MainLayout} from 'components/layouts';
import {
    ArrowCircleLeftIcon,
    CompletedIcon,
    ClosedIcon,
    CalendarIcon
} from 'components/assets';
import useUser from 'hooks/useUser';
import {useNavigate} from 'react-router-dom';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import styled from 'styled-components';
import {useGetFileDetail} from 'hooks';
import './style.scss';
import LineItem from "./LineItem";
import { formatDateAndTime } from 'utils/utils';

const FileDetail = styled.div`
  //display: flex;
  //width: 100%;
  //height: 100vh;
`;

const DetailPage = () => {
    const [enableFetching, setEnableFetching] = useState(false);
    const {userToken} = useUser();

    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get('fileId');
    const source = queryParams.get('source');

    useEffect(() => {
        if (userToken) {
            setEnableFetching(true);
        }
    }, [userToken]);

    const {isLoading, data, isError} = useGetFileDetail(
        userToken,
        query,
        enableFetching,
    );

    if (isLoading) {
        return (
            <MainLayout hasNavbar={false}>
        <span data-testid="test-loader" className="w-full flex justify-center">
          Files Loading...
        </span>
            </MainLayout>
        );
    }

    if (isError) {
        return (
            <MainLayout hasNavbar={false}>
        <span data-testid="test-error" className="w-full flex justify-center">
          An Error occured When fetching the data
        </span>
            </MainLayout>
        );
    }

    const handleClickBack = () => {
        navigate('/');
    };

    return (
        <MainLayout hasNavbar={false}>
            <FileDetail className="p-[15px] sm:p-[30px] detail-page">
                {/* Back */}
                <div className="flex justify-center relative w-full">
                    <div data-testid="test-back-icon" onClick={handleClickBack}
                         className="flex items-center absolute left-0 ">
                        <ArrowCircleLeftIcon/>
                        <span className="ml-1.5">Back</span>
                    </div>
                    <div data-testid="isVerified" className="text-2xl text-gray-900 font-semibold">
                        {`File #${data?.data?.fileNumber}`}
                    </div>
                </div>
                {/*  Content */}
                <div className="flex-col md:flex md:flex-row items-start w-full md:w-[1000px] mx-auto mt-[15px] md:mt-[50px]">
                    {/*  Left Container */}
                    <div className="mr-0 md:mr-[50px] w-full">
                        {/* Client Name */}
                        <div className="client-name rounded-lg">
                            <LineItem leftText="Client Name" classRightText="font-semibold" rightText={`${data?.data?.clientName}`} />
                            <LineItem leftText="Product Type" classRightText="font-semibold" rightText={`${data?.data?.productType}`}/>
                            <LineItem leftText="Property Address" rightText={`${data?.data?.propertyAddressStreet} ${data?.data?.propertyAddressCity} ${data?.data?.propertyAddressState} ${data?.data?.propertyAddressZip}`} />
                            <LineItem leftText="Source" classRightText="font-semibold" rightText={`${source !== null ? source : data?.data?.fileSource ? data?.data?.fileSource : ""}`}/>
                            <LineItem leftText="Invoice Sent to Accounting" icon={<CalendarIcon/>} classRightText="font-semibold" rightText={`${formatDateAndTime(data?.data?.invoiceSentToAccounting)}`} />
                        </div>
                        {/* Order assigned to */}
                        <div className="bg-white rounded-lg py-[5px] my-[15px] md:my-[30px] drop-shadow-[0_3px_10px_rgba(0,0,0,0.10)]">
                            <LineItem className="bg-orange-50" leftText="Order assigned to" rightText={`${data?.data?.orderAssignedTo}`} classRightText="font-semibold"/>
                            <LineItem leftText="Appraiser List Sent" icon={<CalendarIcon/>} rightText={`${formatDateAndTime(data?.data?.appraiserListSend)}`}/>
                            <LineItem className="bg-green-50" leftText="Review Complete" icon={<CompletedIcon/>} rightText={`${formatDateAndTime(data?.data?.reviewComplete)}`}/>
                            <LineItem className="bg-yellow-50" leftText="Sent to Client" icon={<CalendarIcon/>} rightText={`${formatDateAndTime(data?.data?.sentToClient)}`}/>
                            <LineItem className="bg-gray-50"  leftText="File Closed" icon={<ClosedIcon/>} rightText={`${formatDateAndTime(data?.data?.closeDate)}`}/>
                        </div>
                    {/*    Revisions */}
                    { data?.data?.revisedReportOrdered && data?.data?.revisedReportRecieved && <>
                            <div className="text-sm font-semibold text-gray-900">Revisions</div>
                            <LineItem className="bg-red-50 rounded-lg border border-red-200 my-[10px] sm:my-[20px]" leftText="Revised Report Ordered" icon={<CalendarIcon/>} rightText={`${formatDateAndTime(data?.data?.revisedReportOrdered)}`}/>
                            <LineItem className="bg-red-50 rounded-lg border border-red-200" leftText="Revised Report Received" icon={<CalendarIcon/>} rightText={`${formatDateAndTime(data?.data?.revisedReportReceived)}`}/>
                        </>
                    }
                    </div>

                    {/*  Right Container */}
                    <div className="w-full bg-white rounded-lg mt-[15px] md:mt-0 py-[15px] md:py-[20px] border border-gray-300 drop-shadow-[0_3px_10px_rgba(0,0,0,0.10)]">
                        <div className="text-sm font-semibold text-gray-900 ml-[20px]">Appraisers</div>
                        {
                            data?.data?.appraisers.map((app, index) => {
                                if (index % 2 === 0) {
                                    return (
                                    <div className="">
                                        <LineItem leftText="Appraiser Name" rightText={`${app.appraiserName}`} classRightText="font-semibold"/>
                                        <LineItem leftText="Payment amount" rightText={`$${app.paymentAmount}`}/>
                                        <LineItem leftText="Schedule Site Visit Date" rightText={`${formatDateAndTime(app.scheduleSiteVisitDate)}`} icon={<CalendarIcon/>} />
                                        <LineItem leftText="Report Received" rightText={`${formatDateAndTime(app.reportReceived)}`} icon={<CalendarIcon/>} />
                                        <LineItem leftText="Sent to Accounting" rightText={`${formatDateAndTime(app.billSentToAccounting)}`} icon={<CalendarIcon/>} />
                                        <LineItem leftText="Payment Reference" rightText={`${app.paymentReference ? `#${app.paymentReference}` : ''}`} />
                                        <LineItem leftText="Check Paid" rightText={app.paid ? formatDateAndTime(app.paid) : ''} icon={<CalendarIcon />} />
                                        <LineItem leftText="Check Number" rightText={app.checkNum || ''} />
                                    </div>
                                ); 
                                } else {
                                    return (
                                        <div className="bg-blue-50">
                                            <LineItem leftText="Appraiser Name" rightText={`${app.appraiserName}`} classRightText="font-semibold"/>
                                            <LineItem leftText="Payment amount" rightText={`$${app.paymentAmount}`}/>
                                            <LineItem leftText="Schedule Site Visit Date" rightText={`${formatDateAndTime(app.scheduleSiteVisitDate)}`} icon={<CalendarIcon/>} />
                                            <LineItem leftText="Report Received" rightText={`${formatDateAndTime(app.reportReceived)}`} icon={<CalendarIcon/>} />
                                            <LineItem leftText="Sent to Accounting" rightText={`${formatDateAndTime(app.billSentToAccounting)}`} icon={<CalendarIcon/>} />
                                            <LineItem leftText="Payment Reference" rightText={`${app.paymentReference.includes("ValueLinkSoftware.OrderPayment[]") ? '' : `#${app.paymentReference}`}`} />
                                            <LineItem leftText="Check Paid" rightText={app.paid ? formatDateAndTime(app.paid) : ''} icon={<CalendarIcon />} />
                                            <LineItem leftText="Check Number" rightText={app.checkNum || ''} />
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </FileDetail>
        </MainLayout>
    );
};
export default withAuthenticationRequired(DetailPage);
